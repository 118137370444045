import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/no-cycle
import axios from '@axios'
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'
import useGraphJwt from '@/auth/jwt/useGraphJwt'
import { getUserData, setUserData } from '@/auth/utils'
// import OpenAI from 'openai'
import mime from 'mime-types'

const OPENAI_API_KEY = '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'
const PEXELS_API_KEY = 'PzcAgAfpTBrL81ffcrbig2HiCNfeJBrCS9cvlEvXjx4GkmBiDNuuiKrK'

const SUBSCRIPTION_KEY = '808e55d31ab24c20b5c2b981f173bbd7'
const LOCATION = 'trial'
const ACCOUNT_ID = 'af47f295-5f7d-4be6-a999-0cab93fa8813'

const TOKEN_URL = `https://api.videoindexer.ai/Auth/${LOCATION}/Accounts/${ACCOUNT_ID}/AccessToken`
const UPLOAD_URL = `https://api.videoindexer.ai/${LOCATION}/Accounts/${ACCOUNT_ID}/Videos`
// const SEARCH_URL = `https://api.videoindexer.ai/${LOCATION}/accounts/${ACCOUNT_ID}/videos/search`
const VIDEO_URL = `https://api.videoindexer.ai/${LOCATION}/accounts/${ACCOUNT_ID}/videos`

// const PROMT = 'Eres un experto editor de contenidos de plataformas OTT. Tu misión es rellenar contenidos. Te voy a dar texto referente al títuo y descripción corta de un contenido y debes proporcionarme una descripción más larga. La primera frase es el título de un programa o contenido. Sólo quiero que me respondas la descripción larga. Damelo en el mismo idioma que tenga la descripción corta.'
// const TEXTO = 'El mundo es nuestro es una película española dirigida por Alfonso Sánchez y protagonizada por él mismo y Alberto López'
export const logout = error => {
  let out = false
  error.forEach(element => {
    if (element.message.includes('Error decoding signature') || element.message.includes('Invalid token')) {
      out = true
    }
    console.error(element.message)
  })

  if (out) {
    localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
    localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
    localStorage.removeItem('userData')
    window.location.pathname = '/login'
  }
}
export const formateDateTime = (fechaISO, time = true) => {
  if (fechaISO) {
    const fecha = new Date(fechaISO)
    const dia = fecha.getDate().toString().padStart(2, '0')
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0')
    const anio = fecha.getFullYear()
    const hora = fecha.toLocaleTimeString('es-ES', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    })

    return `${dia}/${mes}/${anio} ${time ? hora : ''}`
  }
  return '--/--/---- --:--:--'
}

export async function getOpenAICompletion(texto, promt) {
  try {
    const response = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${OPENAI_API_KEY}`, // Asegúrate de configurar tu API Key
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        model: 'gpt-4o-mini-2024-07-18',
        messages: [
          { role: 'system', content: promt },
          { role: 'user', content: texto }, // Pasas el contenido como parámetro
        ],
      }),
    })

    const data = await response.json()
    return data.choices[0].message.content // Aquí devuelves el contenido relevante
  } catch (error) {
    console.error('Error fetching OpenAI completion:', error)
    throw error
  }
}
export async function generateImageWithAI(description, type = 'fondo') {
  const prompt = `Crea una imagen de ${type} apartir de esta descripción de un contenido OTT: ${description}`
  try {
    const response = await fetch('https://api.openai.com/v1/images/generations', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${OPENAI_API_KEY}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        model: 'dall-e-3', // Modelo de generación de imágenes
        prompt, // Descripción de la imagen
        size: '1792x1024', // Tamaño de la imagen
        quality: 'standard', // Calidad de la imagen
        style: 'vivid', // Estilo de la imagen
        n: 1, // Número de imágenes a generar
      }),
    })

    // Convertir la respuesta a JSON
    const data = await response.json()

    // Extraer la URL de la imagen generada y el prompt revisado
    const imageUrl = data.data[0].url
    const revisedPrompt = data.data[0].revised_prompt

    // Retornar la URL de la imagen y el prompt revisado
    return { imageUrl, revisedPrompt }
  } catch (error) {
    console.error('Error generating image with OpenAI:', error)
    throw error
  }
}

export async function generateTagsAI(description) {
  const prompt = `Genera 5 etiquetas y devuélvelas como una lista separada por comas, sólo los tags relevantes de una imagen con la siguiente descripción: '${description}'.`

  try {
    const response = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${OPENAI_API_KEY}`, // Asegúrate de tener tu clave de API aquí
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        model: 'gpt-4o-mini-2024-07-18',
        messages: [
          { role: 'system', content: prompt },
          { role: 'user', content: description },
        ],
      }),
    })

    // Convertir la respuesta a JSON
    const data = await response.json()
    const message = data.choices[0].message.content
    // Obtener los tags generados y separarlos por comas
    const tags = message.trim().split(', ')

    // Retornar los tags generados
    return tags
  } catch (error) {
    console.error('Error generating tags with OpenAI:', error)
    throw error
  }
}
export async function searchImagesInPexels(description, type = 'landscape') {
  try {
    // Llamar a la función para generar etiquetas
    const tags = await generateTagsAI(description)

    // Unir las etiquetas para crear una búsqueda más concreta
    const query = tags.join(', ')

    const url = `https://api.pexels.com/v1/search?query=${encodeURIComponent(query)}&per_page=12&page=1&orientation=${type}`
    const headers = {
      Authorization: PEXELS_API_KEY, // Asegúrate de tener tu clave de API aquí
      'User-Agent': 'Curl',
    }

    const response = await fetch(url, { method: 'GET', headers })

    if (response.status === 200) {
      const data = await response.json()
      const photos = data.photos || []

      // Retornar las URL de las imágenes en formato retrato
      return photos.map(photo => photo.src[type])
    }
    console.error(`Error en la solicitud: ${response.status}`)
    return `Error en la solicitud: ${response.status}`
  } catch (error) {
    console.error('Error buscando imágenes en Pexels:', error)
    throw error
  }
}
export async function urlToFile(url, filename, mimeType = 'application/octet-stream') {
  try {
    // Hacer la solicitud HTTP para obtener los datos desde la URL
    const response = await fetch(url)

    // Convertir la respuesta en un Blob (dato binario)
    const blob = await response.blob()

    // Crear un archivo a partir del Blob
    const file = new File([blob], filename, { type: mimeType })

    return file // Devuelve el archivo para que puedas usarlo
  } catch (error) {
    console.error('Error al convertir la URL en un archivo:', error)
    throw error
  }
}
export const camelToSnake = string => {
  // Expresión regular para separar el camel case en palabras
  const s1 = string.replace(/(.)([A-Z][a-z]+)/g, '$1_$2')
  return s1.replace(/([a-z0-9])([A-Z])/g, '$1_$2').toLowerCase()
}

export const cleanUsername = name => {
  try {
    const profileName = getUserData().profile.client.name
    const profileNameArray = profileName.split('_')
    const names = name.split('_')
    const duplicado = []
    let user = ''
    let users = ''
    const arrayTres = names.concat(profileNameArray)
    const arrayUndefined = []

    const unique = arrayTres.filter((item, index) => arrayTres.indexOf(item) !== index)
    if (names[0] === 'deleted') {
      user = name
    } else {
      for (let i = 0; i < unique.length; i += 1) {
        for (let j = 0; j < arrayTres.length; j += 1) {
          if (unique[i] === arrayTres[j]) {
            duplicado.push(arrayTres[j])
            delete (arrayTres[j])
          }
        }
      }
      for (let i = 0; i < arrayTres.length; i += 1) {
        if (arrayTres[i] === undefined) {
          arrayUndefined.push(arrayTres[i])
        }
      }
      if (arrayUndefined.length === arrayTres.length) {
        user = profileName
      } else {
        for (let i = 0; i < arrayTres.length; i += 1) {
          if (arrayTres[i] !== undefined) {
            user += `${arrayTres[i]}_`
          }
        }
      }
    }
    if (user[user.length - 1] === '_') {
      users = user.slice(0, -1)
      return users
    }
    users = user
    return users
  } catch (error) {
    return null
  }
}

export const base64Encode = data => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(data)
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
})
export const limitCharacter = (frase, maxCaracteres) => {
  if ((frase?.length ?? 0) <= maxCaracteres) {
    return frase
  }

  // Cortar la frase a un máximo de caracteres
  let fraseRecortada = frase.substr(0, maxCaracteres)

  // Asegurarse de que no se corte en medio de una palabra
  if (frase[maxCaracteres] !== ' ' && frase[maxCaracteres - 1] !== ' ') {
    // Buscar el último espacio en la frase recortada
    const ultimoEspacio = fraseRecortada.lastIndexOf(' ')

    // Si hay un espacio, recortar hasta el último espacio
    if (ultimoEspacio !== -1) {
      fraseRecortada = fraseRecortada.substr(0, ultimoEspacio)
    }
  }

  // Asegúrate de que la longitud no exceda el máximo después de recortar
  return fraseRecortada.substr(0, maxCaracteres)
}
export const showToast = (title, type, self) => {
  if (type === 1) {
    self.$toast({
      component: ToastificationContent,
      position: 'bottom-right',
      props: {
        title,
        icon: 'CheckIcon',
        variant: 'success',
        position: 'bottom-right',
      },
    })
  } else {
    self.$toast.error({
      component: ToastificationContent,
      position: 'bottom-right',
      closeOnClick: true,
      props: {
        title,
        icon: 'fas fa-times',
        variant: 'error',
        position: 'bottom-right',
      },
    })
  }
}
export const messageError = (result, self) => new Promise(resolve => {
  try {
    const error = result.data.errors
    if (error) {
      error.forEach(element => {
        const cadena = element.message
        const cadenaLimpia = cadena.replace(/'/g, '').replace(/\\/g, '')
        showToast(cadenaLimpia, 2, self)
        resolve(true)
      })
    } else {
      resolve(false)
    }
  } catch (err) {
    console.log(err)
    showToast(i18n.t('errorMakingRequest'), 2, self)
  }
})
export const disabledCampaigns = (campaigns, id) => new Promise((resolve, reject) => {
  let flag = 0
  let query = `
          mutation{`
  campaigns.forEach((c, i) => {
    if (c.node.categories.edges.length !== 0) {
      c.node.categories.edges.forEach((element, a) => {
        if (element.node.id === id) {
          c.node.categories.edges.splice(a, 1)
        }
      })
    }
    if (c.node.contents.edges.length !== 0) {
      c.node.contents.edges.forEach((element, a) => {
        if (element.node.id === id) {
          c.node.contents.edges.splice(a, 1)
        }
      })
    }
    if (c.node.categories.edges.length === 0 && c.node.contents.edges.length === 0) {
      query += `        
                  camp${i + 1}:updateCampaign(id:"${c.node.id}",input:{isActive:false}){
                      campaing{
                      id
                      isActive
                  }
              }
              `
    } else {
      flag += 1
    }
  })

  query += '}'

  if (flag !== campaigns.length) {
    axios.post('', { query }).then(() => {
      resolve(true)
    }).catch(err => {
      reject(err)
      console.log(err)
    })
  }
})
export const disabledAds = (ads, id) => new Promise((resolve, reject) => {
  let flag = 0

  let query = `
          mutation{`
  ads.forEach((a, i) => {
    if (a.node.categories.edges.length !== 0) {
      a.node.categories.edges.forEach((element, n) => {
        if (element.node.id === id) {
          a.node.categories.edges.splice(n, 1)
        }
      })
    }
    if (a.node.contents.edges.length !== 0) {
      a.node.contents.edges.forEach((element, n) => {
        if (element.node.id === id) {
          a.node.contents.edges.splice(n, 1)
        }
      })
    }

    if (a.node.categories.edges.length === 0 && a.node.contents.edges.length === 0) {
      query += `        
                  ad${i + 1}:updateAds(id:"${a.node.id}",input:{isActive:false}){
                      ad{
                          id
                          isActive
                      }
                  }
                  `
    } else {
      flag += 1
    }
  })
  query += '}'

  if (flag !== ads.length) {
    axios.post('', { query }).then(() => {
      resolve(true)
    }).catch(err => {
      console.log(err)
      reject(err)
    })
  }
})
/* FUNCIÓN PARA CREAR,EDITAR Y SOLUCIONAR ERRORES DE LA RELACIÓN CONTENIDO-CATEGORÍA-ORDEN */
export const checkRelationOrder = code => new Promise(resolve => {
  const query = `mutation{
      checkCategoriesOrder(categoryId:"${code}") {
        okCode
      }
    }`
  axios.post('', { query }).then(result => {
    const check = result.data.data.checkCategoriesOrder.okCode
    if (check !== 0) {
      resolve(true)
    } else {
      resolve(false)
    }
  }).catch(err => {
    console.log(err)
    resolve(false)
  })
})
export const checkMultiCategories = ids => new Promise((resolve, reject) => {
  let query = '{'
  ids.forEach((id, i) => {
    query += `check_${i + 1}: allCategories(id:"${id}") {
            edges {
              node {
                id
                name
                categoriesCampaing {
                  edges {
                    node {
                      id
                      name
                      contents {
                        edges {
                          node {
                            id
                            name
                          }
                        }
                      }
                      categories {
                          edges {
                            node {
                              id
                              name
                            }
                          }
                        }
                    }
                  }
                }
                categoriesAd {
                  edges {
                    node {
                      id
                      name
                      contents {
                        edges {
                          node {
                            id
                            name
                          }
                        }
                      }
                      categories {
                          edges {
                            node {
                              id
                              name
                            }
                          }
                        }
                      }
                  }
                }
              }
            }
          }`
  })
  query += '}'

  if (ids.length > 0) {
    axios.post('', { query }).then(result => {
      Object.keys(result.data.data).forEach(key => {
        if (result.data.data[key].edges.length !== 0) {
          const categorie = result.data.data[key].edges
          if (categorie.length !== 0) {
            const campaings = categorie[0].node.categoriesCampaing.edges
            const ads = categorie[0].node.categoriesAd.edges
            if (campaings.length !== 0) {
              disabledCampaigns(campaings, categorie[0].node.id).then(() => {
              }).catch(() => {

              })
            }
            if (ads.length !== 0) {
              disabledAds(ads, categorie[0].node.id).then(() => {

              }).catch(error => {
                console.log(error)
              })
            }
          }
        }
      })
      resolve(true)
    }).catch(err => {
      reject(err)
      console.log(err)
    })
  }
})
export const checkCategories = id => new Promise((resolve, reject) => {
  const query = `{
            allCategories(id:"${id}") {
              edges {
                node {
                  id
                  name
                  categoriesCampaing {
                    edges {
                      node {
                        id
                        name
                        contents {
                          edges {
                            node {
                              id
                              name
                            }
                          }
                        }
                        categories {
                            edges {
                              node {
                                id
                                name
                              }
                            }
                          }
                      }
                    }
                  }
                  categoriesAd {
                    edges {
                      node {
                        id
                        name
                        contents {
                          edges {
                            node {
                              id
                              name
                            }
                          }
                        }
                        categories {
                            edges {
                              node {
                                id
                                name
                              }
                            }
                          }
                        }
                    }
                  }
                }
              }
            }
          }`
  axios.post('', { query }).then(result => {
    const categorie = result.data.data.allCategories.edges
    if (categorie.length !== 0) {
      const campaings = categorie[0].node.categoriesCampaing.edges
      const ads = categorie[0].node.categoriesAd.edges

      resolve({ campaign: campaings, ad: ads })
    }
  }).catch(err => {
    reject(err)
    console.log(err)
  })
})
export const moveToCategory = (oldCategory, newCategory, contents = [], type = 0) => new Promise((resolve, reject) => {
  const query = `
        mutation{
          moveContents(newCategory:"${newCategory}",oldCategory:"${oldCategory}",contentsIds:[${contents}],typeMovement:${type}){
            ok
          }
        }`
  axios
    .post('', {
      query,
    })
    .then(res => {
      checkRelationOrder(newCategory).then(() => {
        resolve(res)
      }).catch(err => {
        console.log(err)
      })
    })
    .catch(err => {
      reject(err)
    })
})

// Limpia de una categoría final el contenido que se le pasa
export const cleanRelationContents = content => new Promise((resolve, reject) => {
  let query = '{'
  content.forEach((id, i) => {
    query += `
                rem_${i + 1}: removeContentsCategories(content:"${id}"){
                        status
                    }
                    `
  })
  query += '}'
  if (content.length > 0) {
    axios.post('', { query }).then(result => {
      console.log(result)
      resolve(true)
    }).catch(err => {
      reject(err)
      console.log(err)
    })
  }
})

export const checkContents = ids => new Promise((resolve, reject) => {
  let query = '{'
  ids.forEach((cont, i) => {
    query += `
           content_${i + 1}: allContents(id:"${cont}") {
                edges {
                    node {
                      id
                      contentsCampaing {
                        edges {
                          node {
                            id
                            name
                            contents {
                              edges {
                                node {
                                  id
                                  name
                                }
                              }
                            }
                            categories {
                                edges {
                                  node {
                                    id
                                    name
                                  }
                                }
                              }
                          }
                        }
                      }
                      contentsAd {
                        edges {
                          node {
                            id
                            name
                            contents {
                              edges {
                                node {
                                  id
                                  name
                                }
                              }
                            }
                            categories {
                                edges {
                                  node {
                                    id
                                    name
                                  }
                                }
                              }
                          }
                        }
                      }
                    }
                  }
                }
              `
  })
  query += '}'
  axios.post('', { query }).then(result => {
    Object.keys(result.data.data).forEach(r => {
      if (result.data.data[r].edges.length !== 0) {
        const { id } = result.data.data[r].edges[0].node

        const campaings = result.data.data[r].edges[0].node.contentsCampaing.edges
        const ads = result.data.data[r].edges[0].node.contentsAd.edges

        if (campaings.length === 0 && ads.length === 0) {
          resolve(true)
        } else {
          if (campaings.length !== 0) {
            disabledCampaigns(campaings, id).then(() => {
              resolve(true)
            }).catch(err => {
              console.log(err)
            })
          }
          if (ads.length !== 0) {
            disabledAds(ads, id).then(() => {
              resolve(true)
            }).catch(err => {
              console.log(err)
            })
          }
        }
      }
    })
  }).catch(err => {
    reject(err)
    console.log(err)
  })
})

export const inputDecimalNumber = data => {
  const existeClave = Object.keys(data.failedRules)
  if (existeClave === 'required') {
    return i18n.t('onlyNumbers')
  }
  return ''
}
// export const isEmpty = value => (value == null || value.length === 0 || value === 'null' || value === undefined)
export const isEmpty = value => {
  if (value == null || value === undefined || value === 'null') {
    return true // Comprueba si es null o undefined
  }

  if (typeof value === 'string' && value.length === 0) {
    return true // Comprueba si es una cadena vacía
  }

  if (typeof value === 'object') {
    // Si es un archivo (instancia de File), verifica el tamaño
    if (value instanceof File) {
      return value.size === 0 // Si el archivo tiene tamaño 0, se considera vacío
    }
    // Comprueba si es un objeto vacío (sin propiedades propias)
    return Object.keys(value).length === 0
  }

  return false // Para cualquier otro caso, devuelve false (no está vacío)
}
export const deactivateSlider = (ids, active = false, type = 'CAT') => new Promise(resolve => {
  let empty = 0
  let query = '{'
  const edgeType = type === 'CAT' ? 'categorySlide' : 'contentSlide'
  ids.forEach((id, i) => {
    if (type === 'CAT') {
      query += `${`all_${i}`}:allCategories(id:"${id}") {
                edges {
                  node {
                    id
                    categorySlide {
                      totalCount
                      edges {
                        node {
                          id
                          isActive
                        }
                      }
                    }
                    
                  }
                }
              }`
    } else {
      query += `${`all_${i}`}:allContents(id:"${id}") {
                edges {
                  node {
                    id
                    contentSlide {
                      totalCount
                      edges {
                        node {
                          id
                          isActive
                        }
                      }
                    }
                    
                  }
                }
              }`
    }
  })
  query += '}'

  axios.post('', {
    query,
  }).then(result => {
    const response = result.data.data
    let queryMutation = 'mutation{'
    ids.forEach((id, i) => {
      if (response[`${`all_${i}`}`].edges[0].node[edgeType].totalCount !== 0) {
        response[`${`all_${i}`}`].edges[0].node[edgeType].edges.forEach(slide => {
          queryMutation += `
                        ${`up_${slide.node.id}`}:updateSlide(id:"${slide.node.id}",input:{isActive:${active}}){
                            slide{
                              id
                              isActive
                            }
                          }`
        })
      } else {
        empty += 1
      }
    })
    queryMutation += '}'
    if (empty < ids.length) {
      axios.post('', {
        query: queryMutation,
      }).then(() => {
        resolve(true)
      }).catch(err => {
        console.log(err)
      })
    }
  }).catch(err => {
    console.log(err)
  })
})
export const identityDocument = dni => new Promise((resolve, reject) => {
  const dniRegex = /(^[ABCDEFGHJKLMNPQRSUVWabcdefghjklmnpqrsuvw]([0-9]{7})([0-9A-Ja]$))|(^[0-9]{8}[A-Za-z]$)|([X-Zx-z]{1})(\d{7})([A-Za-z]{1})/
  if (dni != null) {
    if (dniRegex.test(dni) === true) {
      // código para comprobar que un dni es real o no,
      // no es necesario para la validación, se deja comentado por si es de utilidad en otra ocasión.
      /* number = dni.substr(0, dni.length -1)
                                              letr = dni.substr(dni.length -1, 1)
                                              number = number % 23;
                                              letter = 'TRWAGMYFPDXBNJZSQVHLCKET'
                                              letter = letter.substring(number, number +1)
                                              if (letter != letr.toUpperCase()) {
                                              reject('')
                                              }else {
                                              resolve(false)
                                              } */
      // resuelve dando validez al formato dni con los 8 primeros números, seguido de una letra.
      resolve(false)
    } else {
      // formato dni no válido
      if (dni.length === 9) {
        reject(i18n.t('nifError'))
      }
      if (dni.length !== 9 && dni.length !== 0) {
        reject(i18n.t('figuresPhoneNumberMsg'))
      } else if (isEmpty(dni)) {
        resolve(false)
      }
    }
  }
})

export const postal = post => new Promise((resolve, reject) => {
  if (post != null) {
    const postRegex = /^\d{5}$/
    if (postRegex.test(post)) {
      resolve(false)
    }
    if (isEmpty(post)) {
      resolve(false)
    }
    if (post.length !== 5 && post.length !== 0) {
      reject(i18n.t('figuresPostalMsg'))
      resolve(true)
    }
    if (postRegex.test(post) !== true && post.length === 5) {
      reject(i18n.t('validErrorValidCharacters'))
      resolve(true)
    }
  } else if (isEmpty(post)) {
    resolve(false)
  }
})

// TODO: QUE PASA SI UN NÚMERO ES INTERNACIONAL (MAS DE 9 DÍGITOS?)
export const phoneNumber = phone => new Promise((resolve, reject) => {
  if (phone != null) {
    const phoneRegex = /^\d{9}$/
    if (phoneRegex.test(phone)) {
      resolve(false)
    }
    if (isEmpty(phone)) {
      resolve(false)
    }
    if (phone.length !== 9) {
      reject(i18n.t('figuresPhoneNumberMsg'))
    }
    if (phoneRegex.test(phone) !== true && phone.length === 9) {
      reject(i18n.t('validErrorValidCharacters'))
    }
  } else if (isEmpty(phone)) {
    resolve(false)
  }
})

export const getInitialCategoriesLimit = val => {
  const limit = store.state.app.platformConfiguration.initialCategoryLimit
  const result = limit <= val
  return { result, limit }
}
export const getNestedCategoryLimit = val => {
  const limit = store.state.app.platformConfiguration.nestedCategoryLimit
  const result = limit <= val
  return { result, limit }
}
export const getContentsLimit = val => {
  const limit = store.state.app.platformConfiguration.totalContentsLimit
  const result = limit <= val
  return { result, limit }
}
export const getProductsLimit = val => {
  const limit = store.state.app.platformConfiguration.totalProductsLimit
  const result = limit <= val
  return { result, limit }
}
export const getInteractivitiesLimit = val => {
  const limit = store.state.app.platformConfiguration.totalInteractivityLimit
  const result = limit <= val
  return { result, limit }
}
export const getContentInteractivitiesLimit = val => {
  const limit = store.state.app.platformConfiguration.contentInteractivityLimit
  const result = limit <= val
  return { result, limit }
}
export const utf8ToB64 = str => window.btoa(unescape(encodeURIComponent(str)))
export const b64ToUtf8 = str => decodeURIComponent(escape(window.atob(str)))
export const specialCategory = type => {
  let special = false
  switch (type) {
    case 'NOR':
    case 'COU':
    case 'UNI':
    case 'MOD':
    case 'DIR':
    case 'POD':
    case 'AUL':
    case 'SER':
    case 'FET':
    case 'PRO':
    case 'LIN':
    case 'FIL':
      special = false
      break
    default:
      special = true
      break
  }
  return special
}
export const removeIntResources = (contentId, id) => new Promise(resolve => {
  const query = `{
        allContents(id:"${contentId}") {
          edges {
            node {
              id
              name
              contentResources(content:"${utf8ToB64(`id:${contentId}`)}",resource:"${utf8ToB64(`id:${id}`)}") {
                edges {
                  node {
                    id      
                    isInitial              
                  }
                }
              }
              contentInteractivity {
                edges {
                  node {
                    id
                    resourceEnd {
                      id
                      name
                    }
                    resourceStart {
                        id
                        name
                      }
                  }
                }
              }        
            
            }
          }
        }
      }`

  axios.post('', { query }).then(result => {
    const content = result.data.data.allContents.edges[0].node
    let resources = 0
    const relation = content.contentResources.edges[0].node
    if (!relation.isInitial) {
      content.contentInteractivity.edges.forEach(() => {
        resources += 1
      })

      if (resources < 1) {
        const queryDelete = `
                    mutation{  
                        deleteContentsResource(id:"${relation.id}"){
                        found
                        deletedId
                        }  
                    }`
        axios.post('', { query: queryDelete }).then(() => {
          resolve(true)
        }).catch(err => {
          console.log(err)
        })
      } else resolve(true)
    } else resolve(true)
  }).catch(err => {
    console.log(err)
  })
})
export const removeResources = contentResources => new Promise(resolve => {
  const eliminar = []
  const eliminarNombre = []
  const file = []
  // recorrer contenidos
  Object.keys(contentResources).forEach(element => {
    contentResources[element].edges[0].node.contentResources.edges.forEach(res => {
      if (res.node.resource.resourceContent.totalCount === 1) {
        eliminar.push(res.node.resource.id)
        eliminarNombre.push(res.node.resource.name)
        if (!isEmpty(res.node.resource.file)) {
          file.push(res.node.resource.file.id)
        }
      }
    })
  })

  if (eliminar.length) {
    resolve({ id: [...eliminar], name: [...eliminarNombre], file: [...file] })
  } else {
    resolve(false)
  }
})

export const removeRelation = (category, contents) => new Promise((resolve, reject) => {
  const query = `mutation{
    removeRelationOrder(categoryId:"${category}",contentIds:[${contents}]){
      ok
    }
  }`

  axios.post('', {
    query,
  }).then(result => {
    resolve(result)
  }).catch(err => {
    reject(err)
    console.log(err)
  })
})

export const sortedContents = (id, order) => new Promise(resolve => {
  const query = `mutation{
    sortedContents(categoryId:"${id}",orderType:"${order}") {
        okCode
      }
    }`
  axios.post('', { query }).then(result => {
    const check = result.data.data.sortedContents.okCode
    if (check !== 3 || check !== 0) {
      resolve(true)
    } else {
      resolve(false)
    }
  }).catch(err => {
    console.log(err)
    resolve(false)
  })
})

export const deactivateMenu = (ids, active = false) => new Promise(resolve => {
  let empty = 0
  let query = '{'
  ids.forEach((id, i) => {
    query += `${`all_${i}`}:allCategories(id:"${id}") {
                edges {
                  node {
                    id
                    categoryMenu {
                    totalCount
                      edges {
                        node {
                          id
                          isActive
                        }
                      }
                    }
                    
                  }
                }
              }`
  })
  query += '}'

  axios.post('', {
    query,
  }).then(result => {
    const response = result.data.data
    let queryUpdate = 'mutation{'
    ids.forEach((id, i) => {
      if (response[`${`all_${i}`}`].edges[0].node.categoryMenu.totalCount !== 0) {
        response[`${`all_${i}`}`].edges[0].node.categoryMenu.edges.forEach(menu => {
          queryUpdate += `
                        ${`up_${menu.node.id}`}:updateMenu(id:"${menu.node.id}",input:{isActive:${active}}){
                            menu{
                              id
                              isActive
                            }
                          }`
        })
      } else {
        empty += 1
      }
    })
    queryUpdate += '}'
    if (empty < ids.length) {
      axios.post('', {
        query: queryUpdate,
      }).then(() => {
        resolve(true)
      }).catch(err => {
        console.log(err)
        // resolve(false)
      })
    }
  }).catch(err => {
    console.log(err)
  })
})

export const addContentsToCategory = (category, ids) => new Promise(resolve => {
  const query = `mutation{
    addContents(categoryId:"${category}",contentIds:[${ids}]){
      ok
    }
  }`

  axios.post('', {
    query,
  }).then(result => {
    checkRelationOrder(category).then(() => {
      resolve(result)
    }).catch(err => {
      console.log(err)
    })
  }).catch(err => {
    resolve(err)
    console.log(err)
  })
})
export const isExternalLive = node => {
  try {
    const { edges } = node.contentResources
    if (!edges || edges.length === 0) {
      return false // No hay recursos, no es un stream externo
    }
    const typeStream = edges[0].node.resource?.typeStream
    if (['AWS', 'WOW'].includes(typeStream)) {
      return true // Es 'AWS' o 'WOW', se considera un stream externo
    }
    return false // typeStream es 'AWS' o 'WOW', no es un stream externo
  } catch (error) {
    // console.error('Error al verificar si es un stream externo:', error);
    return false // En caso de error, considerar que no es un stream externo.
  }
}

export const manageLive = async (idContent, service, successMsg, failMsg, icon, self) => {
  try {
    // Obtengo información de recurso inicial del contenido
    const query = `
        query {
          allContents(id:"${idContent}"){
            edges{
              node{
                contentResources (isInitial:true) {
                  edges{
                    node{
                      resource {
                        id
                        typeStream
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `

    const result = await axios.post('', { query })
    const allContents = result.data.data.allContents.edges
    const promises = allContents.flatMap(content => {
      const contentResources = content.node.contentResources.edges
      return contentResources.filter(contentResource => {
        const { typeStream } = contentResource.node.resource
        return ['AWS', 'WOW'].includes(typeStream)
      }).map(async contentResource => {
        try {
          const idResource = contentResource.node.resource.id
          const idClient = b64ToUtf8(self.userData.profile.client.id).split(':')[1]
          const responseManageLive = await axios.post('', {
            query: `
              mutation ($idResource: ID!, $idClient: String) {
                ${service}(idResource: $idResource, idClient: $idClient) {
                  actionDone
                }
              }
            `,
            variables: {
              idResource,
              idClient,
            },
          })

          if (responseManageLive.data.data[service].actionDone) {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: successMsg,
                icon,
                variant: 'success',
              },
            })
            return true
          }
          self.$toast({
            component: ToastificationContent,
            props: {
              title: failMsg,
              icon,
              variant: 'error',
            },
          })
          return false
        } catch (error) {
          console.error(error)
          self.$toast({
            component: ToastificationContent,
            props: {
              title: failMsg,
              icon,
              variant: 'error',
            },
          })
          throw error
        }
      })
    })
    const results = await Promise.all(promises)
    return results.every(Boolean)
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const setClientNode = () => {
  const dataSaved = getUserData()

  const id = dataSaved.profile?.client?.id
  useGraphJwt.refreshClient(id).then(response => {
    const { data } = response.data
    dataSaved.profile.client = data.client

    const ability = {
      ability: [
        {
          action: 'manage',
          subject: 'all',
        },
      ],
    }

    const userData = { ...dataSaved, ...ability }
    setUserData(userData)
    window.location.reload()
  }).catch(err => {
    console.log(err)
  })
}
export const checkFileType = async filePath => {
  // Obtener el tipo MIME basado en la extensión del archivo
  let mimeType = mime.lookup(filePath)
  if (filePath.endsWith('.p')) {
    mimeType = 'video/mp4'
  }

  // Mapear tipos MIME conocidos a tipos de archivos específicos
  const fileTypes = {
    'audio/mpeg': 'MP3',
    'video/mp4': 'MP4',
    'video/quicktime': 'MOV',
    'application/vnd.apple.mpegurl': 'HLS',
    'application/x-mpegurl': 'HLS',
    default: 'Unknown',
  }

  // Devolver el tipo de archivo o 'Unknown' si no está en el mapa
  return fileTypes[mimeType] || fileTypes.default
}

/* AZURE */

/* ======================= FUNCIONES ======================= */

// 1. Función para obtener el token de autenticación
async function obtenerToken() {
  const params = new URLSearchParams({ allowEdit: 'true' })
  const headers = { 'Ocp-Apim-Subscription-Key': SUBSCRIPTION_KEY }

  try {
    const response = await fetch(`${TOKEN_URL}?${params}`, { headers })
    if (response.ok) {
      const token = await response.text()
      return token.replace(/"/g, '') // Eliminar comillas si las hay
    }
    console.error(`Error al obtener token: ${response.status}, ${response.statusText}`)
    return null
  } catch (error) {
    console.error(`Error en la solicitud del token: ${error}`)
    return null
  }
}
async function getScenesVideo(token, id) {
  const headers = { Authorization: `Bearer ${token}` }

  try {
    const response = await fetch(`${VIDEO_URL}/${id}/index`, { headers })
    if (response.ok) {
      const data = await response.json()
      if (data) {
        console.log(`Video INSIGHT: ${data.name}`)
        return data // Retornar el primer resultado encontrado
      }
      console.log('No se encontró ningún video con la búsqueda.')
      return null
    }
    console.error(`Error al buscar video: ${response.status}, ${response.statusText}`)
    return null
  } catch (error) {
    console.error(`Error en la solicitud de búsqueda de video: ${error}`)
    return null
  }
}
// 2. Función para buscar un video en Azure Video Indexer con un query
async function buscarVideo(token, query) {
  const headers = { Authorization: `Bearer ${token}` }
  const params = new URLSearchParams({
    query, // Parámetro de búsqueda
    isBase: 'true', // Definir si se busca solo en la base o en todos los videos
  })

  try {
    const response = await fetch(`${VIDEO_URL}/search?${params}`, { headers })
    if (response.ok) {
      const data = await response.json()
      if (data.results.length > 0) {
        console.log(`Video encontrado: ${data.results[0].name}`)
        const videoInsight = await getScenesVideo(token, data.results[0].id)
        return videoInsight // Retornar el primer resultado encontrado
      }
      console.log('No se encontró ningún video con la búsqueda.')
      return null
    }
    console.error(`Error al buscar video: ${response.status}, ${response.statusText}`)
    return null
  } catch (error) {
    console.error(`Error en la solicitud de búsqueda de video: ${error}`)
    return null
  }
}

// 3. Función para subir un vídeo mediante URL
async function subirVideo(token, videoUrl, nombreVideo = 'Test API') {
  const headers = { Authorization: `Bearer ${token}` }
  const params = new URLSearchParams({
    name: nombreVideo,
    videoUrl,
    description: 'Descripción del video',
    privacy: 'Private',
    partition: 'partition1',
  })

  try {
    const response = await fetch(`${UPLOAD_URL}?${params}`, {
      method: 'POST',
      headers,
    })

    if (response.ok) {
      const data = await response.json()
      const videoId = data.id
      console.log(`Vídeo subido exitosamente. Video ID: ${videoId}`)
      return videoId
    }
    console.error(`Error al subir video: ${response.status}, ${response.statusText}`)
    return null
  } catch (error) {
    console.error(`Error en la solicitud de subida de video: ${error}`)
    return null
  }
}

// 4. Función para obtener el estado de procesamiento del video
async function obtenerEstadoVideo(token, videoId) {
  const videoInfoUrl = `https://api.videoindexer.ai/${LOCATION}/Accounts/${ACCOUNT_ID}/Videos/${videoId}/Index`
  const headers = { Authorization: `Bearer ${token}` }

  try {
    const response = await fetch(videoInfoUrl, { headers })
    if (response.ok) {
      return await response.json()
    }
    console.error(`Error al obtener estado del video: ${response.status}, ${response.statusText}`)
    return null
  } catch (error) {
    console.error(`Error en la solicitud de estado del video: ${error}`)
    return null
  }
}

// 5. Función recursiva para esperar el procesamiento del video
async function esperarProcesamientoVideo(token, videoId) {
  const videoInfo = await obtenerEstadoVideo(token, videoId)

  if (videoInfo && videoInfo.state === 'Processed') {
    console.log('Video procesado exitosamente.')
    return videoInfo
  } if (videoInfo && videoInfo.state === 'Failed') {
    console.error('Fallo en el procesamiento del video.')
    return null
  }
  console.log('Procesando... (esperando 30 segundos)')
  // Espera 30 segundos antes de volver a intentar
  await new Promise(resolve => setTimeout(resolve, 30000))
  return esperarProcesamientoVideo(token, videoId) // Llamada recursiva para verificar el estado
}

// 6. Función principal para buscar o procesar el video
export async function procesarVideo(videoUrl, query) {
  // Obtener token
  const token = await obtenerToken()
  if (!token) return null // Asegurarse de devolver algo si no hay token

  // Buscar video en Azure primero
  const videoEncontrado = await buscarVideo(token, query)
  if (videoEncontrado) {
    console.log('Video ya existente, no es necesario subirlo nuevamente.')
    return videoEncontrado // Si ya se encontró el video, retornar directamente
  }

  // Si no se encuentra el video, proceder a subirlo
  console.log('No se encontró el video, procediendo a subirlo.')
  /* eslint-disable */
  return null
  const videoId = await subirVideo(token, videoUrl, query)
  if (!videoId) return null // Asegurarse de devolver algo si no se sube el video

  // Esperar que el video sea procesado completamente
  const videoInfo = await esperarProcesamientoVideo(token, videoId)

  // Obtener resultados de la IA si el video fue procesado
  if (videoInfo) {
    const insights = videoInfo?.videos?.[0]?.insights || {}
    return insights
  }

  // En caso de que no se procese el video, devolver null
  return null
}
