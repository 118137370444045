import * as constants from '@core/utils/constants'

export default [

  {
    path: '/vendor/category',
    name: 'vendor-category',
    meta: { activeItem: 'vendor-category', rol: constants.vendor },

    component: () => import('@/views/vendor/categories/CategoryVendor.vue'),
  },
  {
    path: '/vendor/category/:code',
    name: 'vendor-subCategory',
    meta: { activeItem: 'vendor-category', rol: constants.vendor },

    component: () => import('@/views/vendor/categories/CategoryVendor.vue'),
  },
  {
    path: '/vendor/category/edit/:id',
    name: 'edit-vendor-category',
    meta: { activeItem: 'vendor-category', rol: constants.vendor },

    component: () => import('@/views/vendor/categories/edit/index.vue'),
  },
  {
    path: '/vendor/category/create/:id/:order',
    name: 'create-vendor-category',
    meta: { activeItem: 'vendor-category', rol: constants.vendor },

    component: () => import('@/views/vendor/categories/FormWizardCategoryShop.vue'),
  },

  {
    path: '/vendor/products/edit/:id',
    name: 'edit-vendor-products',
    meta: { activeItem: 'vendor-category', rol: constants.vendor },

    component: () => import('@/views/vendor/products/edit/index.vue'),
  },
  {
    path: '/vendor/orders',
    name: 'vendor-orders',
    meta: { rol: constants.vendor },

    component: () => import('@/views/vendor/orders/OrdersVendor.vue'),
  },
  {
    path: '/vendor/refunds/create',
    name: 'create-vendor-refunds',
    meta: { activeItem: 'vendor-refunds', rol: constants.vendor },

    component: () => import('@/views/vendor/refunds/formWizardRefunds.vue'),
  },
  {
    path: '/vendor/refunds',
    name: 'vendor-refunds',
    meta: { activeItem: 'vendor-refunds', rol: constants.vendor },

    component: () => import('@/views/vendor/refunds/RefundsVendor.vue'),
  },
  {
    path: '/vendor/refunds/edit/:id',
    name: 'edit-vendor-refunds',
    meta: { activeItem: 'vendor-refunds', rol: constants.vendor },

    component: () => import('@/views/vendor/refunds/editRefunds.vue'),
  },
  {
    path: '/vendor/pagos',
    name: 'vendor-payments',
    meta: { activeItem: 'vendor-payments', rol: constants.vendor },

    component: () => import('@/views/vendor/payments/PaymentsVendor.vue'),
  },

]
