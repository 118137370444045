import * as constants from '@core/utils/constants'

export default [{
  path: '/users/administrators',
  name: 'administrators-list',
  meta: { rol: constants.gestorUser },

  component: () => import('@/views/users/administrators/AdministratorsList.vue'),
},
{
  path: '/users/administrators/edit/:id',
  name: 'administrators-edit',
  meta: { activeItem: 'administrators-list', rol: constants.gestorUser },
  component: () => import('@/views/users/administrators/AdministratorsEdit.vue'),
},
{
  path: '/users/administrators/wizard',
  name: 'administrators-wizard',
  meta: { activeItem: 'administrators-list', rol: constants.gestorUser },
  component: () => import('@/views/users/administrators/FormAdminUser.vue'),
},
{
  path: '/users/final-users',
  name: 'final-users-list',
  meta: { rol: constants.gestorUser },

  component: () => import('@/views/users/final-users/FinalUsersList.vue'),
},
{
  path: '/users/final-users/wizard',
  name: 'final-users-wizard',
  meta: { activeItem: 'final-users-list', rol: constants.gestorUser },
  component: () => import('@/views/users/final-users/FormFinalUser.vue'),
},
{
  path: '/users/final-users/edit/:id',
  name: 'final-users-edit',
  meta: { activeItem: 'final-users-list', rol: constants.gestorUser },
  component: () => import('@/views/users/final-users/FinalUsersEdit.vue'),
},
{
  path: '/users/profile/:id',
  name: 'profile-page',

  component: () => import('@/views/users/profilePage.vue'),
},
]
