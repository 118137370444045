import * as constants from '@core/utils/constants'

export default [
  /* SETTINGS */
  {
    path: '/platform/settings',
    name: 'settings',
    meta: { rol: constants.administrador },
    component: () => import('@/views/platform/settings/Settings.vue'),
  },
  /* MYCHANNEL */
  {
    path: '/platform/my-channel',
    name: 'myChannel',
    meta: { rol: constants.administrador },
    component: () => import('@/views/platform/my-channel/MyChannel.vue'),
  },
  {
    path: '/platform/my-channel/:id',
    name: 'myChannel-list',
    meta: { activeItem: 'myChannel', rol: constants.administrador },
    component: () => import('@/views/platform/my-channel/MyChannel.vue'),
  },
  {
    path: '/platform/my-channel/edit/:id',
    name: 'myChannel-edit',
    meta: { activeItem: 'myChannel', rol: constants.administrador },
    component: () => import('@/views/platform/my-channel/edit/index.vue'),
  },
  {
    path: '/platform/my-channel/wizard/:parent',
    name: 'myChannel-create',
    meta: { activeItem: 'myChannel', rol: constants.administrador },
    component: () => import('@/views/platform/my-channel/FormWizardChannel.vue'),
  },
  /* BRANDING */
  {
    path: '/platform/branding',
    name: 'branding',
    meta: { rol: constants.dis },
    component: () => import('@/views/platform/branding/Branding.vue'),
  },
  /* MENÚ */
  {
    path: '/platform/menu',
    name: 'menu',
    meta: { rol: constants.dis },
    component: () => import('@/views/platform/menu/menuList.vue'),
  },
  /* NOTIFICATIONS */
  {
    path: '/platform/notifications',
    name: 'notifications',
    meta: { activeItem: 'notifications', rol: [constants.gestorNotifications] },
    component: () => import('@/views/platform/notifications/PushNotification.vue'),
  },
  {
    path: '/platform/edit/notifications/:id',
    name: 'edit-notifications',
    meta: { activeItem: 'notifications', rol: [constants.gestorNotifications] },
    component: () => import('@/views/platform/notifications/edit/index.vue'),
  },
  {
    path: '/platform/create/notifications',
    name: 'create-notifications',
    meta: { activeItem: 'notifications', rol: [constants.gestorNotifications] },
    component: () => import('@/views/platform/notifications/create/index.vue'),
  },
  {
    path: '/platform/old/notifications',
    name: 'create-notifications',
    meta: { activeItem: 'notifications', rol: [constants.gestorNotifications] },
    component: () => import('@/views/platform/notifications/OldPushNotification.vue'),
  },
  /* SLIDER */
  {
    path: '/platform/slider',
    name: 'slider',
    meta: { activeItem: 'slider', rol: [constants.dis, constants.editorCont] },
    component: () => import('@/views/platform/slider/slider.vue'),
  },
  {
    path: '/platform/slider/create-slide/:idSlider/:type/:position',
    name: 'slide-create',
    meta: { activeItem: 'slider', rol: [constants.dis, constants.editorCont] },
    component: () => import('@/views/platform/slider/WizardSlide.vue'),
  },
  {
    path: '/platform/slider/create-slide/:idSlider/:type/:position',
    name: 'slide-create-sponsor',
    meta: { activeItem: 'slider', rol: [constants.dis, constants.editorCont] },
    component: () => import('@/views/platform/slider/WizardSlide.vue'),
  },
  {
    path: '/platform/slider/edit-slide/:id/:type',
    name: 'slide-edit',
    meta: { activeItem: 'slider', rol: [constants.dis, constants.editorCont] },
    component: () => import('@/views/platform/slider/edit/index.vue'),
  },

  /* ZONES */
  {
    path: '/platform/zones',
    name: 'zones',
    meta: { activeItem: 'zones', rol: constants.administrador },
    component: () => import('@/views/platform/zones/Zones.vue'),
  },
  /*  {
    path: '/platform/zones/edit/:id',
    name: 'zones-edit',
    meta: { activeItem: 'zones', rol: constants.administrador },
    component: () => import('@/views/platform/zones/oldZones/EditZones.vue'),
  },
  {
    path: '/platform/zones/create/:type',
    name: 'zones-create',
    meta: { activeItem: 'zones', rol: constants.administrador },
    component: () => import('@/views/platform/zones/oldZones/FormWizardZones.vue'),
  }, */

  /* INAPPPURCHASE */
  {
    path: '/platform/purchases',
    name: 'purchases',
    meta: { rol: constants.administrador },

    component: () => import('@/views/platform/purchases/Purchases.vue'),
  },
  {
    path: '/platform/purchases/edit/:id',
    name: 'purchases-edit',
    meta: { activeItem: 'purchases', rol: constants.administrador },
    component: () => import('@/views/platform/purchases/EditPurchases.vue'),
  },
  {
    path: '/platform/purchases/create',
    name: 'purchases-create',
    meta: { activeItem: 'purchases', rol: constants.administrador },
    component: () => import('@/views/platform/purchases/FormWizardPurchases.vue'),
  },

  /* SUBSCRIPTIONS */
  {
    path: '/platform/subscriptions',
    name: 'subscriptions',
    meta: { rol: constants.administrador },
    component: () => import('@/views/platform/subscriptions/Subscriptions.vue'),
  },
  {
    path: '/platform/subscriptions/edit/:id',
    name: 'subscriptions-edit',
    meta: { activeItem: 'subscriptions', rol: constants.administrador },
    component: () => import('@/views/platform/subscriptions/EditSubscriptions.vue'),
  },

  {
    path: '/platform/subscriptions/create',
    name: 'subscriptions-create',
    meta: { activeItem: 'subscriptions', rol: constants.administrador },
    component: () => import('@/views/platform/subscriptions/FormWizardSubscriptions.vue'),
  },
  /* PROMOTIONAL CODES */
  {
    path: '/platform/promotional-codes',
    name: 'promotional-codes',
    meta: { rol: constants.administrador },
    component: () => import('@/views/platform/promotional-codes/index.vue'),
  },
  {
    path: '/platform/use-promotional-codes',
    name: 'use-codes',
    meta: { rol: constants.administrador },
    component: () => import('@/views/platform/promotional-codes/usePromotionalCodes.vue'),
  },

]
