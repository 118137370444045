import * as constants from '@core/utils/constants'

export default [
  /* SEARCH */
  {
    path: '/shop/search',
    name: 'products-search',
    meta: { activeItem: 'category-shop', rol: constants.administrador },
    component: () => import('@/views/contents/search/searchProd.vue'),
  },
  {
    path: '/shop/vendor',
    name: 'vendor',
    meta: { activeItem: 'vendor', rol: constants.administrador },

    component: () => import('@/views/shop/vendor/Vendors.vue'),
  },
  {
    path: '/shop/vendor/create',
    name: 'create-vendor',
    meta: { activeItem: 'vendor', rol: constants.administrador },

    component: () => import('@/views/shop/vendor/formWizardVendor.vue'),
  },

  {
    path: '/shop/vendor/edit/:id',
    name: 'edit-vendor',
    meta: { activeItem: 'vendor', rol: constants.administrador },

    component: () => import('@/views/shop/vendor/editVendor.vue'),
  },
  {
    path: '/shop/category-shop',
    name: 'category-shop',
    meta: { activeItem: 'category-shop', rol: constants.administrador },

    component: () => import('@/views/shop/categories/CategoriesShop.vue'),
  },
  {
    path: '/shop/category-shop/:code',
    name: 'subCategory-shop',
    meta: { activeItem: 'category-shop', rol: constants.administrador },

    component: () => import('@/views/shop/categories/CategoriesShop.vue'),
  },
  {
    path: '/shop/category-shop/edit/:id',
    name: 'edit-category-shop',
    meta: { activeItem: 'category-shop', rol: constants.administrador },

    component: () => import('@/views/shop/categories/edit/index.vue'),
  },
  {
    path: '/shop/category-shop/create/:id/:order',
    name: 'subCategory-shop-create',
    meta: { activeItem: 'category-shop', rol: constants.administrador },

    component: () => import('@/views/shop/categories/FormWizardCategoryShop.vue'),
  },
  {
    path: '/shop/category-shop/create/:order',
    name: 'category-shop-create',
    meta: { activeItem: 'category-shop', rol: constants.administrador },

    component: () => import('@/views/shop/categories/FormWizardCategoryShop.vue'),
  },
  {
    path: '/shop/orders',
    name: 'orders',
    meta: { rol: constants.administrador },

    component: () => import('@/views/shop/orders/Orders.vue'),
  },
  {
    path: '/shop/orders/:idFinalOrder',
    name: 'subOrders',
    meta: { activeItem: 'orders', rol: constants.administrador },

    component: () => import('@/views/shop/orders/Orders.vue'),
    props: true,
  },
  {
    path: '/shop/products/edit/:id',
    name: 'edit-products',
    meta: { activeItem: 'category-shop', rol: constants.administrador },

    component: () => import('@/views/shop/products/edit/index.vue'),
  },
  {
    path: '/shop/product/create/:id',
    name: 'add-product',
    meta: { activeItem: 'category-shop', rol: constants.administrador },

    component: () => import('@/views/shop/products/addProductsShop.vue'),
  },
  {
    path: '/shop/products',
    name: 'shop',
    meta: { rol: constants.administrador },

    component: () => import('@/views/shop/products/Products.vue'),
  },

  {
    path: '/shop/refunds/create',
    name: 'create-refunds',
    meta: { activeItem: 'refunds', rol: constants.administrador },

    component: () => import('@/views/shop/refunds/formWizardRefunds.vue'),
  },
  {
    path: '/shop/refunds',
    name: 'refunds',
    meta: { activeItem: 'refunds', rol: constants.administrador },

    component: () => import('@/views/shop/refunds/Refunds.vue'),
  },
  {
    path: '/shop/refunds/edit/:id',
    name: 'edit-refunds',
    meta: { activeItem: 'refunds', rol: constants.administrador },

    component: () => import('@/views/shop/refunds/editRefunds.vue'),
  },
  {
    path: '/shop/pagos',
    name: 'payments',
    meta: { activeItem: 'payments', rol: constants.administrador },

    component: () => import('@/views/shop/payments/Payments.vue'),
  },

]
