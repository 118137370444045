import * as constants from '@core/utils/constants'

export default [
  /* CATEGORÍAS */
  {
    path: '/contents/categories',
    name: 'contents-categories',
    meta: { activeItem: 'contents-categories', rol: [constants.editorCont, constants.sponsor] },
    component: () => import('@/views/contents/categories/CategoriesList.vue'),
  },

  {
    path: '/contents/categories/:id',
    name: 'contents-subcategories',
    meta: { activeItem: 'contents-categories', rol: [constants.editorCont, constants.sponsor] },
    component: () => import('@/views/contents/categories/CategoriesList.vue'),
  },
  {
    path: '/contents/categories/:id/:parent/:link',
    name: 'link-subcategories',
    meta: { activeItem: 'contents-categories', rol: [constants.editorCont, constants.sponsor] },
    component: () => import('@/views/contents/categories/CategoriesList.vue'),
  },
  {
    path: '/contents/categories/:id/:link',
    name: 'link-categories',
    meta: { activeItem: 'contents-categories', rol: [constants.editorCont, constants.sponsor] },
    component: () => import('@/views/contents/categories/CategoriesList.vue'),
  },
  {
    path: '/contents/category/create/:order',
    name: 'category-create',
    meta: { activeItem: 'contents-categories', rol: [constants.editorCont, constants.sponsor] },
    component:
            () => import('@/views/contents/categories/FormWizardCategory.vue'),
  },
  {
    path: '/contents/category/create/:id/:order',
    name: 'subCategory-create',
    meta: { activeItem: 'contents-categories', rol: [constants.editorCont, constants.sponsor] },
    component:
            () => import('@/views/contents/categories/FormWizardCategory.vue'),
  },
  {
    path: '/contents/category/edit/:id',
    name: 'category-edit',
    meta: { activeItem: 'contents-categories', rol: [constants.editorCont, constants.sponsor] },
    component: () => import('@/views/contents/categories/edit/index.vue'),
  },
  /* CONTENIDOS */

  {
    path: '/contents/contents/edit/:id',
    name: 'contents-edit',
    meta: { activeItem: 'contents-categories', rol: [constants.editorCont, constants.sponsor] },
    component: () => import('@/views/contents/contents/edit/index.vue'),
  },
  {
    path: '/contents/contents/create/:id',
    name: 'contents-create',
    meta: { activeItem: 'contents-categories', rol: [constants.editorCont, constants.sponsor] },
    component: () => import('@/views/contents/contents/FormWizardContent.vue'),
  },
  /* MEDIA */
  {
    path: '/contents/media',
    name: 'contents-media',
    meta: { rol: [constants.editorCont, constants.sponsor] },
    component: () => import('@/views/contents/media/Media.vue'),
  },
  /* REELS */
  {
    path: '/contents/reels',
    name: 'contents-reels',
    meta: { rol: [constants.editorCont, constants.sponsor] },
    component: () => import('@/views/contents/reels/Reels.vue'),
  },
  {
    path: '/contents/reels/add',
    name: 'contents-reels-add',
    meta: { rol: [constants.editorCont, constants.sponsor] },
    component: () => import('@/views/contents/reels/ReelsAdd.vue'),
  },
  /* RESOURCES */
  {
    path: '/contents/resources',
    name: 'contents-resources',
    meta: { rol: [constants.editorCont, constants.sponsor] },

    component: () => import('@/views/contents/resources/ResourcesList.vue'),
  },
  {
    path: '/contents/media-resource/edit/:id',
    name: 'resources-media-edit',
    meta: { activeItem: 'contents-resources', rol: [constants.editorCont, constants.sponsor] },

    component: () => import('@/views/contents/resources/edit/index.vue'),
  },
  {
    path: '/contents/media-resource/test-detail/:id',
    name: 'resources-test-detail',
    meta: { rol: [constants.editorCont, constants.sponsor] },

    component: () => import('@/views/contents/resources/detailed-views/TestDetailed.vue'),
  },
  {
    path: '/contents/resources/create/:type',
    name: 'resources-create',
    meta: { activeItem: 'contents-resources', rol: [constants.editorCont, constants.sponsor] },

    component: () => import('@/views/contents/resources/FormWizardResource.vue'),
  },

  /* INTERACTIVIDADES */
  {
    path: '/contents/interactivities',
    name: 'contents-interactivities',
    meta: { rol: [constants.editorCont, constants.sponsor] },
    component: () => import('@/views/interactivities/view/interactivities.vue'),
  },
  {
    path: '/contents/interactivities/edit/:id/:content',
    name: 'interactivities-edit',
    meta: { activeItem: 'contents-categories', rol: [constants.editorCont, constants.sponsor] },
    component: () => import('@/views/interactivities/edit/index.vue'),
  },
  {
    path: '/contents/interactivities/view/:id/:content',
    name: 'interactivities-view',
    meta: { activeItem: 'contents-categories', rol: [constants.editorCont, constants.sponsor] },
    component: () => import('@/views/interactivities/view/ViewInteractivities.vue'),
  },
  {
    path: '/contents/interactivities/create/:id/:resource',
    name: 'interactivities-create',
    meta: { activeItem: 'contents-categories', rol: [constants.editorCont, constants.sponsor] },
    component: () => import('@/views/interactivities/create/CreateInteractivities.vue'),
  },
  {
    path: '/contents/interactivities/wizard/:id/:resource/:str',
    name: 'interactivities-wizard',
    meta: { activeItem: 'contents-categories', rol: [constants.editorCont, constants.sponsor] },
    component: () => import('@/views/interactivities/create/FormWizardInteractivities.vue'),
  },
  {
    path: '/contents/interactivities/video/:id',
    name: 'interactivities-video',
    meta: { activeItem: 'contents-categories', rol: [constants.editorCont, constants.sponsor] },
    component: () => import('@/views/interactivities/view/interactivitieScreen.vue'),
  },
  /* SEARCH */
  {
    path: '/contents/contents/search',
    name: 'contents-search',
    meta: { activeItem: 'contents-categories', rol: [constants.editorCont, constants.sponsor], keepAlive: true },
    component: () => import('@/views/contents/search/searchPage.vue'),
  },
  /* PODCAST */
  {
    path: '/contents/podcast/categories',
    name: 'categories-podcast',
    meta: { activeItem: 'categories-podcast', rol: [constants.editorCont] },
    component: () => import('@/views/contents/podcast/PodcastCategoriesList.vue'),
  },
  {
    path: '/contents/podcast/categories/:id',
    name: 'subcategories-podcast',
    meta: { activeItem: 'categories-podcast', rol: [constants.editorCont] },
    component: () => import('@/views/contents/podcast/PodcastCategoriesList.vue'),
  },
  {
    path: '/contents/podcast/categories/edit/:id',
    name: 'edit-podcast',
    meta: { activeItem: 'categories-podcast', rol: [constants.editorCont] },
    component: () => import('@/views/contents/categories/edit/index.vue'),
  },
  {
    path: '/contents/podcast/create/categories',
    name: 'create-podcast',
    meta: { activeItem: 'categories-podcast', rol: [constants.editorCont] },
    component: () => import('@/views/contents/categories/FormWizardCategory.vue'),
  },
  {
    path: '/contents/podcast/categories/contents/edit/:id',
    name: 'podcast-edit',
    meta: { activeItem: 'categories-podcast', rol: [constants.editorCont] },
    component: () => import('@/views/contents/contents/edit/index.vue'),
  },

  {
    path: '/contents/podcast/categories/contents/create/:id',
    name: 'podcast-create',
    meta: { activeItem: 'categories-podcast', rol: [constants.editorCont] },
    component: () => import('@/views/contents/podcast/PodcastContentsWizard.vue'),
  },
  /* LIVE */
  {
    path: '/contents/live/contents',
    name: 'contents-live',
    meta: { activeItem: 'contents-live', rol: [constants.editorCont] },
    component: () => import('@/views/contents/live/LiveCategoriesList.vue'),
  },
  {
    path: '/contents/live/contents/edit/:id',
    name: 'live-edit',
    meta: { activeItem: 'contents-live', rol: [constants.editorCont] },
    component: () => import('@/views/contents/contents/edit/index.vue'),
  },
  {
    path: '/contents/live/contents/create',
    name: 'live-create',
    meta: { activeItem: 'contents-live', rol: [constants.editorCont] },
    component: () => import('@/views/contents/live/LiveContentsWizard.vue'),
  },
  /* EPG */
  {
    path: '/contents/epg',
    name: 'contents-epg',
    meta: { rol: [constants.editorCont] },
    component: () => import('@/views/contents/epg/Epg.vue'),
  },
  {
    path: '/contents/epg/contents/:id',
    name: 'contents-epg-contents',
    meta: { activeItem: 'contents-epg', rol: [constants.editorCont] },
    component: () => import('@/views/contents/epg/EpgContent.vue'),
  },
  {
    path: '/contents/epg/contents/edit/:epg',
    name: 'contents-epg-edit',
    meta: { activeItem: 'contents-epg', rol: [constants.editorCont] },
    component: () => import('@/views/contents/epg/edit/index.vue'),
  },
  {
    path: '/contents/epg/contents/wizard/:epg/:time',
    name: 'contents-epg-wizard',
    meta: { activeItem: 'contents-epg', rol: [constants.editorCont] },
    component: () => import('@/views/contents/epg/FormWizardEpgContent.vue'),
  },
]
