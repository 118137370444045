import * as constants from '@core/utils/constants'

export default [

  /* DASHBOARD */
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: { keepAlive: true },

    component: () => import('@/views/dashboard/dashboard/Dashboard.vue'),
  },

  /* STATISTICS */
  {
    path: '/dashboard/statistics',
    name: 'dashboard-statistics',
    meta: { rol: constants.gestorStatis },

    component: () => import('@/views/dashboard/statistics/Statistics.vue'),
  },

  /* CLOUDWATCH */
  {
    path: '/dashboard/CDN',
    name: 'dashboard-cdn',
    meta: { rol: constants.gestorStatis },

    component: () => import('@/views/dashboard/cloudwatch/Cloudwatch.vue'),
  },
]
