import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
// import apps from './routes/apps'
import * as constants from '@core/utils/constants'
import dashboard from './routes/dashboard'
import platform from './routes/platform'
import superuser from './routes/superuser'
import users from './routes/users'
import avatar from './routes/avatar'
import fastChannels from './routes/fastChannels'
import pages from './routes/pages'
import contents from './routes/contents'
import shop from './routes/shop'
import vendor from './routes/vendor'
import advertising from './routes/advertising'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: '/',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  /* TODO */
  routes: [
    { path: '/', redirect: { name: 'dashboard' } },
    // ...apps,
    ...dashboard,
    ...contents,
    ...shop,
    ...vendor,
    ...users,
    ...avatar,
    ...fastChannels,
    ...platform,
    ...superuser,
    ...advertising,
    ...pages,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  try {
    const userData = getUserData()
    if (userData) {
      if (!userData.isSuperuser) {
        const roles = userData.groups.edges[0].node.name
        if (roles !== constants.administrador) {
          if (Array.isArray(to.meta.rol)) {
            let noPasaras = true
            to.meta.rol.forEach(element => {
              if (element === roles) {
                noPasaras = false
              }
            })
            if (noPasaras) {
              return next({ name: 'misc-not-authorized' })
            }
          } else if (to.meta.rol !== undefined && to.meta.rol !== roles && roles !== constants.administrador) {
            return next({ name: 'misc-not-authorized' })
          }
        }
      }
    }
    // eslint-disable-next-line no-empty
  } catch (error) {
  }

  if (_.name !== null && (to.name === 'dashboard' || to.name === 'contents-search')) {
    if (_.name !== 'contents-edit' && _.name !== 'dashboard' && _.name !== 'contents-search') {
      // eslint-disable-next-line no-param-reassign
      to.meta.keepAlive = false
    }
  }

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })
    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  const toCategories = to.name !== 'contents-categories'
  const toCategoriesU = to.name !== 'category-edit'
  const toContentsV = to.name !== 'contents-view'
  const toContentsU = to.name !== 'contents-edit' && to.name !== 'contents-create'
  if (localStorage.getItem('levelNavigation') && toCategories && toContentsV && toContentsU && toCategoriesU) {
    localStorage.removeItem('levelNavigation')
    localStorage.removeItem('brItems')
  }
  // Redirect if logged in

  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    // eslint-disable-next-line no-nested-ternary
    next(getHomeRouteForLoggedInUser(userData ? userData.isSuperuser ? 'admin' : userData.role : null))
  }

  return next()
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')

  if (appLoading) {
    // appLoading.style.display = 'none'
    appLoading.classList.add('fadeOut')
  }
})
export default router
