import * as constants from '@core/utils/constants'

export default [{
  path: '/advertising/campaigns',
  name: 'campaigns-list',
  meta: { activeItem: 'campaigns-list', rol: [constants.publi, constants.sponsor] },
  component: () => import('@/views/publicidad/campaigns/CampaignsList.vue'),
},
{
  path: '/advertising/campaigns/create',
  name: 'campaigns-create',
  meta: { activeItem: 'campaigns-list', rol: [constants.publi, constants.sponsor] },
  component: () => import('@/views/publicidad/campaigns/FormWizardCampaign.vue'),
},
{
  path: '/advertising/campaigns/edit/:id',
  name: 'campaigns-edit',
  meta: { activeItem: 'campaigns-list', rol: [constants.publi, constants.sponsor] },
  component: () => import('@/views/publicidad/campaigns/EditCampaign.vue'),
},
{
  path: '/advertising/ads/:campaign',
  name: 'ads-list',
  meta: { activeItem: 'campaigns-list', rol: [constants.publi, constants.sponsor] },
  component: () => import('@/views/publicidad/ads/AdsList.vue'),
},
{
  path: '/advertising/ads/edit/:id/',
  name: 'ads-edit',
  meta: { activeItem: 'campaigns-list', rol: [constants.publi, constants.sponsor] },

  component: () => import('@/views/publicidad/ads/EditAds.vue'),

},
{
  path: '/advertising/ads/view/:id/:campaign',
  name: 'ads-view',
  meta: { activeItem: 'campaigns-list', rol: [constants.publi, constants.sponsor] },

  component: () => import('@/views/publicidad/ads/AdsView.vue'),

},
{
  path: '/advertising/ads/create/:campaign',
  name: 'ads-create',
  meta: { activeItem: 'campaigns-list', rol: [constants.publi, constants.sponsor] },

  component: () => import('@/views/publicidad/ads/FormWizardAds.vue'),

},
]
