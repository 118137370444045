// eslint-disable-next-line import/no-cycle
import { clientNode } from '../utils'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns) {
    this.axiosIns = axiosIns

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `JWT ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              // Update accessToken in localStorage
              this.setToken(r.data.data.refreshToken.token)

              this.onAccessTokenFetched(r.data.data.refreshToken.token)
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `JWT ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken = () => localStorage.getItem('token')

  setToken = value => localStorage.setItem('token', value)

  // localStorage.setItem('username', username)
  login(username, password) {
    return this.axiosIns.post('', {
      variables: {
        username,
        password,
      },
      query: `
        mutation($username: String!, $password: String!){
          tokenAuth(username: $username, password: $password) {
            token
            payload
            refreshExpiresIn
            user{
              id
              firstName
              username
              lastName
              email
              isSuperuser
              groups {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              profile {
                profileVendor {
                  edges {
                    node {
                      id
                      vendorName
                    }
                  }
                }
                client {
                  ${clientNode()}
                }
              }
            }
          }
        }
        `,
    })
  }

  lastLogin(id) {
    const lastLogin = new Date()

    return this.axiosIns.post('', {
      variables: {
        id,
        lastLogin,
      },
      query: `
        mutation($id: ID!, $lastLogin: DateTime){
          updateProfile(id: $id,input:{ lastLogin: $lastLogin}){
            profile{
              id
              lastLogin
            }
          }
        }
        `,
    })
  }

  refreshClient(client) {
    return this.axiosIns.post('', {
      variables: {
        id: client,
      },
      query: `
            query($id:ID!){        
              client(id:$id) {
                ${clientNode()}
              }
          }
        `,
    })
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post('', {
      variables: {
        token: this.getToken(),
      },
      query: `
        mutation($token: String){
          refreshToken(token: $token) {
            token
            payload
            refreshExpiresIn
          }
        }
      `,
    })
  }
}
